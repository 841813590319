.header {
	color: #fff;
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 4;
	position: fixed;
	top: 30px;
	padding: 0px 50px;
	transition: 0.3s ease-in-out;
	.logo {
		h2 {
			strong {
				color: var(--grey);
			}
		}
	}
	.hamburger{
		display: none;
	}
	.navlinks {
		display: flex;
		align-items: center;
		width: fit-content;
		.link_wrapper{
            height: fit-content;
            overflow-y: hidden;
            cursor: pointer;
			position: relative;
			margin: 0px 10px;
			z-index: 5;
            p {
                font-weight: 600;
                transition: ease-in;
                padding: 10px;
                // overflow-y: hidden;
                color: var(--grey);
                text-shadow: 0px 30px 0px #fff;
                transform: translateY(0px);
                transition: 0.6s cubic-bezier(0.76, 0, 0.24, 1);
            }
        }
        .link_wrapper:hover{
            p {
                transform: translateY(-30px);
            }
        }
	}
}


@media only screen and (max-width:800px){
	.header{
		flex-direction: column;
		justify-content: center;
		top: 0px;
		overflow: hidden;
		height: 80px;
		background-color: var(--background_secondary);
		width: 100vw;
		transition: 0.6s height ease-in-out;
		.hamburger{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 40px;
			width: 40px;
			// background-color: red;
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
			.ham_line1,.ham_line2,.ham_line3{
				width: 30px;
				height: 2px;
				background-color: var(--grey);
				transition: 0.5s ease-in-out;
			}
		}
		.nav_close{
			.ham_line1{
				transform: translate(0px, -5px);
			}
			.ham_line2{
				transform: translate(0px, 0px);
				opacity: 1;
			}
			.ham_line3{
				transform: translate(0px, 5px);
			}
		}
		.nav_open{
			.ham_line1{
				transform: translate(-2px, 0px) rotate(405deg);
			}
			.ham_line2{
				transform: translate(-20px, 0px);
				opacity: 0;
			}
			.ham_line3{
				transform: translate(-2px, -4px) rotate(-405deg);
			}
		}
		.logo{
			position: absolute;
			left: 20px;
			top: 20px;
			// background-color: red;
		}
		.navlinks{
			flex-direction: column;
			position: absolute;
			top: 50vh;
			left: 50vw;
			transform: translate(-50%,-50%);
			.link_wrapper{
				margin: 0px;
			}
		}
	}
}
