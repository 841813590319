.about {
  width: 100%;
  min-height: 100vh;
  .about_homepage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .dropdown_wrapper {
      width: fit-content;
      height: fit-content;
      position: absolute;
      bottom: 10vh;
      right: 10vw;
      cursor: pointer;
      svg {
        animation: dropdown 0.8s infinite linear;
      }
    }
    h1 {
      color: #fff;
      font-size: 48px;
      position: relative;
      z-index: 3;
    }
  }
  .about_me {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background_secondary);
    .about_me_wrapper {
      width: 60%;
      height: fit-content;
      background-color: var(--background_secondary);
      position: relative;
      z-index: 2;
      border: 1px solid var(--borders);
      color: #fff;
      padding: 30px 20px;
      h3 {
        text-align: center;
        padding-bottom: 20px;
        font-size: 24px;
      }
      .info_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .image_wrapper {
          width: 40%;
          img {
            width: 80%;
            border-radius: 10px;
          }
        }
        .text_wrapper {
          width: 60%;
          p,
          a {
            font-size: 14px;
            color: var(--p);
          }
          .info_sec {
            .info_half_1,
            .info_half_2 {
              margin: 20px auto;
              p {
                strong {
                  color: var(--green);
                }
              }
            }
          }
          .contact_btn {
            text-transform: none;
            font-weight: 600;
            overflow: hidden;
            border-radius: 0px;
            border: 1px solid var(--green);
            position: relative;
            padding: 5px 15px;
            width: fit-content;
            cursor: pointer;
            p {
              position: relative;
              z-index: 3;
              color: var(--green);
              transition: 0.4s ease-in-out;
            }
            .test {
              position: absolute;
              top: 0px;
              left: 0px;
              height: 1px;
              width: 1px;
              background-color: var(--green);
              z-index: 2;
              border-radius: 50%;
              transform: translate(-50%, -50%) scale(0);
              transition: transform 0.5s ease-in-out;
            }
          }
          .contact_btn:hover {
            p {
              color: #fff;
            }
            .test {
              transform: translate(-50%, -50%) scale(200);
            }
          }
        }
      }
    }
  }
  .languages {
    width: 100%;
    min-height: 100vh;
    padding: 10vh 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      // text-align: center;
      color: #fff;
      padding-bottom: 20px;
      font-size: 24px;
      width: 60%;
      text-align: left;
    }
    .languages_wrapper {
      width: 60%;
      position: relative;
      background-color: var(--background_primary);
      height: fit-content;
      z-index: 2;
      border: 1px solid var(--borders);
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 40px;
      .language {
        width: 100%;
        padding: 10px;
        .language_title {
          font-size: 18px;
          color: var(--grey);
        }
        .level {
          margin-top: 5px;
          width: 100%;
          border-radius: 10px;
          //   overflow: hidden;
          height: 1px;
          background-color: var(--borders);

          .level_indicator {
            position: relative;

            height: 1px;
            background-color: var(--green);
            &::after {
              position: absolute;
              right: 0;
              bottom: 7.5px;
              transform: translateX(50%);
              content: attr(data-level);
              border: 1px solid var(--green);
              padding: 3px 8px;
              font-size: 10px;
              color: var(--green);
              border-radius: 2px;
              background-color: var(--background_primary);
            }
            &::before {
              position: absolute;
              content: "";
              bottom: 5px;
              height: 5px;
              width: 5px;
              background-color: var(--green);
              right: 0;
              transform: translateX(50%) rotate(45deg);
            }
          }
        }
      }
    }
  }
  .skills {
    width: 100%;
    min-height: 100vh;
    padding: 10vh 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      // text-align: center;
      color: #fff;
      padding-bottom: 20px;
      font-size: 24px;
      width: 60%;
      text-align: left;
    }
    .skills_wrapper {
      width: 60%;
      position: relative;
      background-color: var(--background_primary);
      height: fit-content;
      z-index: 2;
      border: 1px solid var(--borders);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-flow: row;
      flex-wrap: wrap;
      .skill_container {
        width: 19vw;
        height: fit-content;
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: red;
        .outer_box {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          position: relative;
          margin-bottom: 20px;
          .inner_box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 150px;
            width: 150px;
            background-color: var(--background_primary);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              color: var(--grey);
            }
          }
        }
        p {
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .about {
    .about_me {
      .about_me_wrapper {
        .info_wrapper {
          .text_wrapper {
            .info_sec {
              display: flex;
              justify-content: space-between;
              .info_half_1,
              .info_half_2 {
                width: 50%;
                margin: 40px auto;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-2.5vh);
  }
  100% {
    transform: translateY(00px);
  }
}

@media only screen and (max-width: 800px) {
  .about {
    .about_me {
      .about_me_wrapper {
        width: 100%;
        padding: 20px;
        .info_wrapper {
          flex-direction: column;
          align-items: center;
          .image_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
          }
          .text_wrapper {
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
              text-align: center;
            }
          }
        }
      }
    }
    .languages {
      h3 {
        text-align: center;
      }
      .languages_wrapper {
        width: 100%;
        padding: 20px;
      }
    }
    .skills {
      h3 {
        text-align: center;
      }
      .skills_wrapper {
        width: 100%;
        flex-direction: column;
        .skill_container {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
