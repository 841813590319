.contact {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15vh;
  h1 {
    color: #fff;
  }
  .input_wrapper {
    width: 50%;
    background-color: var(--background_primary);
    position: relative;
    z-index: 2;
    border: 1px solid var(--borders);
    margin-top: 20px;
    padding: 30px;
    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: flex-end;
    }
    label {
      color: var(--p) !important;
      padding-bottom: 10px;
    }
    input,
    textarea {
      color: var(--p);
      background: var(--background_primary);
      //   border-radius: 5px;
      width: 100%;
      padding: 10px;
      border: 1px solid var(--borders);
      &:focus-visible {
        border: 1px solid var(--green);
        outline: none;
        border-radius: 5px;
      }
    }
    input::placeholder,
    textarea::placeholder {
      color: var(--grey);
    }
    .user_details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        border: none;
      }
    }
    .message_input {
      width: 100%;
      background-color: var(--background_secondary);
      border-radius: 10px;
      padding: 10px;
      border: none;
      outline: none;
      resize: none;
      color: #fff;
    }
    .message_input::placeholder {
      color: #4e4e4e;
    }
    .bottom_div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .contact_btn {
        text-transform: none;
        font-weight: 600;
        overflow: hidden;
        border-radius: 0px;
        border: 1px solid var(--green);
        padding: 5px 15px;
        width: fit-content;
        position: relative;
        cursor: pointer;
        p {
          position: relative;
          z-index: 3;
          color: var(--green);
          transition: 0.4s ease-in-out;
        }
        .test {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 1px;
          width: 1px;
          background-color: var(--green);
          z-index: 2;
          border-radius: 50%;
          transform: translate(-50%, -50%) scale(0);
          transition: transform 0.5s ease-in-out;
        }
      }
      .contact_btn:hover {
        p {
          color: #fff;
        }
        .test {
          transform: translate(-50%, -50%) scale(200);
        }
      }
    }
    .contact_btn {
      text-transform: none;
      font-weight: 600;
      overflow: hidden;
      border-radius: 0px;
      border: 1px solid var(--green);
      padding: 5px 15px;
      width: fit-content;
      position: relative;
      cursor: pointer;
      background-color: var(--background_primary);
      p {
        position: relative;
        z-index: 3;
        color: var(--green);
        transition: 0.4s ease-in-out;
      }
      .test {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 1px;
        width: 1px;
        background-color: var(--green);
        z-index: 2;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.5s ease-in-out;
      }
    }
    .contact_btn:hover {
      p {
        color: #fff;
      }
      .test {
        transform: translate(-50%, -50%) scale(200);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .contact {
    .input_wrapper {
      width: 100%;
      input {
        width: 25vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    width: 100%;
    .input_wrapper {
      width: 100%;
      input {
        width: 80vw !important;
        margin: 10px 0px;
      }
      .user_details {
        flex-direction: column;
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}
