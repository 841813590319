@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  /* border: 1px solid red; */
}
*::-webkit-scrollbar{
  display: none;
}
:root{
  --layers: #242424;
  --background_primary: #0C0C0C;
  --background_secondary: #121212;
  --borders: #161f18;
  --grey: #989898; 
  --green:#01EE99;
  --p: #E5E5E5;
}