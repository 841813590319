.small_contact {
  width: fit-content;
  position: fixed;
  left: 50px;
  bottom: 50px;
  z-index: 3;
  p,
  a {
    color: var(--grey);
    font-size: 14px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 800px) {
  .small_contact {
    display: none;
  }
}
