.home {
  width: 100%;
  position: relative;
  height: 100vh;
  padding: 0px 50px 0px 50px;
  z-index: 2;
  // background-color: red;
  .profile_img {
    width: 50%;
    img {
      height: 90vh;
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translate(0%, -50%);
    }
  }
  .intro_text {
    width: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    color: #fff;
    p {
      font-size: 1.5vw;
      strong {
        color: var(--green);
      }
    }
  }
  .changing_text {
    position: absolute;
    bottom: 10vh;
    right: 10%;
    color: #fff;
    font-size: 28px;
    width: 50vw;
    // background-color: red;
    h2 {
      font-size: 3vw;
      strong {
        color: var(--green);
        position: relative;
      }
      ::after {
        position: absolute;
        content: " ";
        background-color: var(--green);
        height: 100%;
        width: 2px;
        margin-left: 5px;
        animation: cursor infinite 0.2s linear;
        transform: translateY(-5px);
      }
    }
  }
  .home_social_links {
    display: none;
  }
}

@keyframes cursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .home {
    .intro_text {
      p {
        font-size: 18px;
      }
    }
    .changing_text {
      width: 500px;
      h2 {
        font-size: 32px;
        width: 80vw;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .home {
    width: 100vw;
    overflow-x: hidden;
    .profile_img {
      img {
        height: 60vh;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .intro_text {
      top: 65%;
      left: 0%;
      width: 100%;
      padding: 20px;
      text-align: center;
    }
    .changing_text {
      h2 {
        font-size: 26px;
      }
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      padding: 10px;
      height: 100px;
    }
    .home_social_links {
      display: flex;
      width: fit-content;
      z-index: 100;
      position: absolute;
      bottom: 7.5vh;
      left: 50%;
      transform: translateX(-50%);
      a {
        margin: 0px 10px;
        svg {
          color: var(--grey);
          cursor: pointer;
          transition: 0.3s ease-in-out;
        }
        svg:hover {
          color: var(--green);
        }
      }
    }
  }
}
