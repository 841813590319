.services_wrapper {
  width: 100%;
  min-height: 100vh;
  .services_homepage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      font-size: 48px;
      position: relative;
      z-index: 3;
    }
    position: relative;
    .dropdown_wrapper {
      width: fit-content;
      height: fit-content;
      position: absolute;
      bottom: 10vh;
      right: 10vw;
      cursor: pointer;
      svg {
        animation: dropdown 0.8s infinite linear;
      }
    }
  }
  .services {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background_secondary);
    h3 {
      width: 60%;
      text-align: left;
      color: #fff;
    }
    .services_container {
      width: 60%;
      height: fit-content;
      padding: 30px 0px;
      position: relative;
      z-index: 2;
      .flexbox {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-flow: row;
        flex-wrap: wrap;
        .service {
          min-width: 300px;
          width: 40%;
          margin: 20px 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border: 1px solid var(--borders);
          padding: 10px 20px 30px 20px;
          background: var(--background_secondary);
          svg {
            width: 48px;
            height: 48px;
          }
          .service_heading {
            font-size: 16px;
            font-weight: 600;
            margin: 10px 0px;
            color: #fff;
          }
          .service_desc {
            color: var(--p);
            font-size: 14px;
            // text-align: center;
          }
        }
      }
    }
  }
}
@keyframes dropdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-2.5vh);
  }
  100% {
    transform: translateY(00px);
  }
}

@media only screen and (max-width: 800px) {
  .services_wrapper {
    .services {
      padding-top: 10vh;
      h3 {
        text-align: center;
        font-size: 24px;
      }
      .services_container {
        width: 100%;
        .flexbox {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
